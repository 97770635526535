@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import "./variables";
@import "./assets/fonts/fonts.css";

body {
  padding: 0;
  margin: 0;
  background-image: url("../src/assets/img/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.root {
  display: flex;
}

.map-root {
  height: calc(100vh - 79px);
  top: 79px !important;
}

.map-container {
  height: 100%;
  width: 100%;
}

.MuiInput-underline {
  border-bottom: solid 1px var(--primary-main);
}

.ag-theme-alpine {
  --ag-background-color: transparent !important;
  --ag-odd-row-background-color: transparent !important;
  --ag-selected-row-background-color: rgba(61, 61, 61, 0.3) !important;
  --ag-row-hover-color: rgba(188, 188, 188, 0.3) !important;
  --ag-secondary-border-color: #ddd !important;
}

.ag-header-cell-label {
  justify-content: center;
}

.ag-cell {
  text-align: left;
  border: 1px transparent !important;
}

.ag-body-horizontal-scroll-viewport {
  overflow-x: hidden !important;
}

.ag-cell-value {
  // overflow: hidden;
  overflow: visible !important;
}

.ag-row-drag {
  margin-right: 9px !important;
}

#menuBookmarkslisting .ag-header,
#menuBookmarkslisting .ag-body-horizontal-scroll,
#menuBookmarkslisting .ag-body-vertical-scroll {
  display: none;
}

#menuBookmarkslisting .ag-root-wrapper {
  border: none;
}

#menuBookmarkslisting .ag-center-cols-viewport {
  overflow-x: hidden;
}

#menuBookmarkslisting .ag-text-field-input {
  margin-left: 8px;
}

.MuiDialogTitle-root {
  color: var(--primary-main);
}

.MuiPaper-root {
  border: solid 1px var(--primary-main);
}

.mantine-Modal-header {
  padding: 0px;
  padding-right: 5px;
  padding-top: 5px;
}

.mantine-Modal-inner {
  padding-left: 0px;
}

.mantine-TextInput-input {
  border: none;
  border-bottom: solid 1px var(--primary-main);
  padding: 0px;
}

.Edit .mantine-TextInput-input, .subTitleEdit .mantine-TextInput-input {
  padding-left: calc(2.25rem / 3);
  padding-right: calc(2.25rem / 3);
  min-height: 19px !important;
  height: 19px !important;
}

.Edit .mantine-Select-input {
  min-height: 19px !important;
  height: 19px !important;
}

.mantine-Dialog-closeButton {
  top: 5px;
  right: 5px;
}

.mantine-ScrollArea-scrollbar {
  display:none !important;
}

.mantine-Image-root {
  height: 100% !important;
}

.mantineBtn {
}

// #raceboard.board-opened {
//   animation: linearwipe 0.7s steps(100, end), flash 1s steps(100, end);
// }

.racePartyName, .racePartyName2, .racePartyNameW {
  font-family: "FranklinGothicHeavy" !important;
  white-space: nowrap;
}

.raceTitle {
  font-family: "FranklinGothicBook" !important;
  margin-top: 0.7rem;
}

.raceTitleGov {
  font-family: "FranklinGothicBook" !important;
  margin-top: 0rem;
}

.raceName {
  font-family: "FranklinGothicDemiCompressed" !important;
  margin-bottom: 0.2rem;
}

.percent {
  font-size: 2.2rem;
}

.percent2 {
  font-size: 1.5rem;
}

.percentBig2 {
  font-size: 3rem;
}

.percent3 {
  font-size: 1.3rem;
}

.percentNum {
  font-weight: normal !important;
  margin-top: 0.7rem;
}

.percentNum2 {
  font-weight: normal !important;
  margin-top: -0.2rem;
  font-size: 4.3rem;
}

.percentNumTurnout {
  font-weight: normal !important;
  font-family: "FranklinGothicMedium";
  font-size: 6.5rem;
  line-height: 3rem;
  text-align: center;
}

.regNum {
  font-family: "FranklinGothicDemi";
  font-size: 2.3rem;
  font-weight: bold;
  line-height: 3rem;
  text-align: center;
  padding-bottom: 1rem;
}

.percentNumGN {
  font-weight: normal !important;
  margin-top: -0.5rem;
}

.percentNumGov {
  font-weight: normal !important;
  margin-top: 0rem;
}

.percentNumCong {
  font-weight: normal !important;
}

@keyframes linearwipe {
  from {
    background: linear-gradient(
        rgba(133, 133, 133, 0.3),
        rgba(133, 133, 133, 0.3)
      )
      left/0% 100% no-repeat;
  }
  to {
    background-size: 200% 200%;
    background-position: left;
  }
}

@keyframes flash {
  from {
    opacity: 0;
  }
  to {
  }
}

#racechart.board-opened {
  width: calc(100% - 7.5% - 0px - 0%) !important;
}

#cover.fade-out {
  animation: fadeout 1s steps(100, end);
  animation-fill-mode: forwards;
}

@keyframes fadeout {
  from {
    opacity: 1;
    z-index: 999;
  }
  to {
    opacity: 0;
    z-index: -2;
    display: none;
  }
}

.mantine-Navbar-root .mantine-Text-root {
  background-color: rgba(188, 188, 188, 0.3);
  margin-top: 4px;
  margin-bottom: 4px;
  margin-left: 0px;
  padding-top: 4px;
  padding-bottom: 4px;
  text-align: center;
  border-left: solid 13px #5a5a5a;
}

.mantine-Navbar-root .mantine-Group-root {
  margin-left: -48px;
}

.mantine-ActionIcon-root {
  color: #000;
}

.mantine-ActionIcon-root:hover {
  background-color: rgba(254, 254, 254, 0.5) !important;
}

.mantine-Switch-track {
  background-color: #000 !important;
  border-color: #000 !important;
  color: #fff !important;
  font-size: 10px;
}

.mantine-Modal-overlay {
  //z-index: 201 !important;
}

.mantine-NumberInput-label {
  word-break: inherit !important;
}

.mantine-NumberInput-input {
  padding-left: 3px !important;
}

@media (min-width: 48em) {
  .mantine-Navbar-root {
    width: 7.5%;
    min-width: 7.5%;
  }
}

.chrome-picker {
  width: 145px !important;
}

.mapboxgl-map {
  font-family: "Libre Franklin", sans-serif !important;
}

.mapboxgl-ctrl {
  display: none;
}

.mapboxgl-popup-content {
  padding: 1px 12px;
  font-size: 11px;
  // background-color: rgba(238,238,238,0.8);
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(8px);
  // border: solid 2px $primary_hex;
  border: solid 2px #ffffff;
}

.mapboxgl-popup-tip {
  border-top-color: #eeeeee !important;
  border-bottom-color: #eeeeee !important;
  display: none;
}

.mapbox-improve-map {
  display: none;
}

.flexbox-fix input {
  background-color: #fff;
}

.topBar {
  box-shadow: none !important;
  height: 79px;
}

.raceCandBox {
  margin-top: -7px;
  display: flex;
  align-items: flex-end;
  height: 100%;
}

.raceCandBox2 {
  margin-top: -6px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  height: 100%;
}

// Mapbox attribution section
.mapboxgl-ctrl-bottom-left {
  left: 8%;
}
.mapboxgl-ctrl-attrib-inner > a[title*="OpenStreetMap"] {
  display: none;
}
// END Mapbox attribution section

.mapboxgl-popup-content {
  padding: 0;
  margin: 0;
  width: 280px;
  background-color: #D5D1FA;
  border: solid 0px #D5D1FA;
  padding-bottom: 0.3rem;
  border-bottom-left-radius: 3px 3px;
  border-bottom-right-radius: 3px 3px;
  box-shadow: 1px 1px 10px #222;
  color: #000;
}

.mapPopup {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  line-height: 2rem;
  font-weight: bold;
}

.mapPopupDist {
  flex: 1;
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1.3rem;
  padding-top: 0.5rem;
  margin-bottom: -0.4rem;
}

.mapPopupTitle {
  flex: 1;
  color: white;
  font-size: 1.7rem;
  margin-bottom: 0rem;
  //font-weight: 600;
  padding-top: 0.2rem;
}

.mapPopupLocation {
  flex: 1;
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1.3rem;
}

.mapPopupParty {
  flex: 1;
  font-size: 1.3rem;
  line-height: 2.2rem;
  margin-bottom: 0rem;
  margin-top: 0.3rem;
  font-weight: 800;
}

.mapPopupVotes {
  flex: 1;
  color: #000;
  font-size: 1.4rem;
  line-height: 2.5rem;
  font-weight: 600 !important;
}

.mapPopupValue {
  flex: 1;
  color: #000;
  font-size: 1.4rem;
  line-height: 3.5rem;
  font-weight: 600 !important;
}

.mapPopupTurnout {
  flex: 1;
  color: #000;
  font-size: 1.4rem;
  line-height: 2.5rem;
  font-weight: 600 !important;
  margin-top: -2%;
  margin-bottom: -1%;
}

.voteNum {
  font-family: "FranklinGothicMedium";
  font-size: 0.9rem;
  margin-top: -1.6rem;
  flex: 1;
  margin-top: 7%;
  color: #111;
}

.voteNum2 { 
  font-family: "FranklinGothicMedium" !important;
  font-size: 4.31rem;
  margin-top: -1.6rem;
  flex: 1;
  margin-top: 6%;
  color: #111;
  line-height: 2.1rem;
}

.voteNumBig2 { 
  font-family: "FranklinGothicMedium" !important;
  font-size: 6rem;
  margin-top: -1.6rem;
  flex: 1;
  margin-top: 6%;
  color: #111;
  line-height: 2.1rem;
}

.voteNumTurnout {
  font-family: "FranklinGothicDemi";
  font-size: 1.6rem;
  font-weight: bold;
  color: #555;
}

.voteNumCong {
  font-family: "FranklinGothicMedium";
  font-size: 1rem;
  color: #111;
}

.raceTitleWomen {
  font-family: "FranklinGothicMedium";
  color: inherit;
  font-size: 1.7rem;
  margin-bottom: 19px !important;
  margin-top: -5px;
}

.raceInterina {
  font-family: "FranklinGothicDemi";
  font-size: 1rem;
  color: #555;
  position: relative;
  top: 9px;
}

.raceNameWomen {
  font-family: "FranklinGothicDemiCompressed";
  font-size: 1.8rem !important;
  line-height: 1.7rem;
}

.topTitleText {
  font-family: "FranklinGothicDemi";
  padding-top: 11px;
  text-transform: uppercase;
}

.raceboard, .raceboardP, .raceboardTurnout, .raceboardNWomen, .raceboardWomen, .raceboard2024, .raceboardGN {
  background-color: rgba(213,209,250,0.8);
  //backdrop-filter: blur(3px);
  border-radius: 5px;
}

.raceboard2024Winner {
  width: 500px !important;
}

.raceboardWomen, .raceboardGN, .raceboard2024 {
  background-color: rgba(213,209,250,0) !important;
  box-shadow: none !important;
}

.partyBox, .partyBoxW, .partyBoxNT, .partyBoxNW {
  background-color: rgba(213,209,250,1);
  border-radius: 5px;
}

.projectRowImg {
  width: 55%;
  border-radius: 6px;
  box-shadow: 3px 3px 10px #999;
}

@media screen and (min-width: 3000px) {
  :root {
    --ag-grid-size: 11px !important;
  }

  .mantine-Navbar-root {
    margin-top: 200px;
  }

  .mantine-Dialog-closeButton {
    scale: 2;
    top: 20px;
    right: 20px;
  }

  .home,
  .back {
    font-size: 35px !important;
    margin-bottom: 15px;
    margin-top: 0px !important;
    padding: 12px 0px !important;
    font-weight: 500;
  }

  .tabler-icon-home {
    width: 2.4rem;
    height: 2.4rem;
  }

  .mantine-Navbar-root .mantine-Text-root {
    font-size: 29px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 6px;
    padding-bottom: 6px;
    border-left: solid 15px #5a5a5a;
    font-weight: 600;
  }

  .mantine-Navbar-root .mantine-Input-input,
  .mantine-Navbar-root .mantine-InputWrapper-label,
  .menuLabel {
    font-size: 28px;
  }

  .mantine-Navbar-root .mantine-NumberInput-input {
    height: 49px;
  }

  .mantine-Navbar-root .mantine-Group-root {
    font-size: 29px;
    padding-top: 12px;
    padding-bottom: 12px;
    font-weight: 600;
  }

  .mantine-Navbar-root .mantine-UnstyledButton-root {
    border-left: solid 35px #000;
    padding-left: 40px;
  }

  .mantine-Switch-track {
    font-size: 14px;
  }
  
  .mantineBtn {
    padding-left: 1.125rem !important;
    border-left: none !important;
    font-size: 25px;
    padding-bottom: 3px;
    left: -94px;
  }

  .mapboxgl-popup-content {
    scale: 2;
  }

  .raceboard {
    width: 1062px !important;
    //border-left: solid 12px $primary_hex !important;
    font-size: 31px !important;
    top: 220px;
    border-radius: 10px;
  }
  
  .raceboardP {
    width: 1100px !important;
    //border-left: solid 12px $primary_hex !important;
    font-size: 31px !important;
    top: 220px;
    border-radius: 10px;
  }
  
  .raceboardWomen {
    width: 1020px !important;
    //border-left: solid 12px $primary_hex !important;
    font-size: 31px !important;
    top: 220px;
    border-radius: 10px;
  }
  
  .raceboard2024 {
    width: 1260px !important;
    //border-left: solid 12px $primary_hex !important;
    font-size: 31px !important;
    top: 220px;
    border-radius: 10px;
  }
  
  .raceboard2024Winner {
    width: 812px !important;
  }
  
  .winnerCheck {
    font-size: 9rem;
  }

  .raceboardTurnout {
    font-size: 31px !important;
    border-radius: 10px !important;
  }

  .raceboardNWomen {
    font-size: 31px !important;
    border-radius: 10px !important;
  }

  .raceboardNWomenRow {
    height: 180px !important;
  }
  
  .raceboardNWomenLabel {
    font-size: 78px !important;
    height: 78px !important;
    line-height: 78px !important;
  }

  .raceboardNWomenWins {
    font-size: 160px !important;
    line-height: 180px !important;
    border-radius: 10px !important;
    height: 180px !important;
  }

  .raceboardGN {
    width: 820px !important;
    //border-left: solid 12px $primary_hex !important;
    font-size: 31px !important;
    top: 220px;
    border-radius: 10px;
  } 
  
  .raceboardTitleBox {
    //border-bottom: solid 7px #111 !important;
    margin-bottom: 20px !important;
  }
  
  .raceboardTitleBoxW {
    //border-bottom: solid 7px #111 !important;
    margin-bottom: 4px !important;
    border-radius: 10px;
    padding-left: 40px;
  }
  
  .raceboardTitleBoxGN {
    //border-bottom: solid 7px #111 !important;
    margin-bottom: 4px !important;
    border-radius: 10px;
    padding-left: 4px;
  }

  .raceboardTitle {
    font-size: 95px !important;
    line-height: 90px !important;
    padding-top: 19px !important;
    font-weight: 600;
  }
  
  .raceboardPRTitle {
    font-size: 86px !important;
    line-height: 160px !important;
    font-weight: 600;
    height: 160px !important;
  }
  
  .raceboardPRSubtitle {
    font-size: 59px !important;
    font-weight: 300;
    line-height: 53px !important;
    margin-bottom: -10px !important;
  }
  
  .raceboardPRTextL {
    font-size: 59px !important;
  }
  
  .raceboardPRTextR {
    font-size: 120px !important;
    line-height: 110px !important;
  }
  
  .raceboardPRTopSpace {
    padding-top: 45px !important;
  }
  
  .raceboardPRBottomSpace {
    height: 90px !important;
  }

  .raceboardNTTitle {
    font-size: 78px !important;
    line-height: 192px !important;
    font-weight: 600;
    height: 192px !important;
  }

  .raceboardNTTitleState {
    font-size: 78px !important;
    line-height: 80px !important;
    font-weight: 600;
    height: 80px !important;
  }

  .raceboardNTSubtitle {
    font-size: 40px !important;
    font-weight: 600;
    line-height: 56px !important;
    height: 56px !important;
  }
  
  .raceboardTitleW {
    font-size: 105px !important;
    line-height: 105px !important;
    padding-top: 42px !important;
    font-weight: 600;
    padding-bottom: 17px;
  }
  
  .raceboardTitleGN {
    font-size: 85px !important;
    line-height: 105px !important;
    padding-top: 15px !important;
    font-weight: 600;
    padding-bottom: 17px;
  }

  .raceboardSubtitle {
    font-size: 51px !important;
    font-weight: 600;
    line-height: 50px !important;
    margin-bottom: -10px !important;
  }

  .raceCheck {
    margin-left: 0px !important;
    width: 40px !important;
    height: 40px !important;
  }

  .partyBox {
    padding-top: 2.45rem !important;
    padding-bottom: 1.45rem;
    margin-top: 10px !important;
    margin-bottom: 0.9rem !important;
    height: 25%;
    border-radius: 10px;
  }

  .partyBoxNT {
    margin-top: 8px !important;
    padding-top: 1.2rem !important;
    margin-bottom: 1rem !important;
  }

  .partyBoxNW {
    margin-top: 8px !important;
  }
  
  .partyBoxW {
    padding-top: 1.5rem !important;
    margin-top: 8px !important;
    //margin-bottom: 0.9rem !important;
    height: 25%;
    border-radius: 10px;
  }

  .partySubBox {
    //#border-bottom: solid 2px #111 !important;
    margin-bottom: 1rem !important;
  }

  .party4SubBox {
    //#margin-bottom: 10px !important;
  }

  .racePartyName {
    font-family: "FranklinGothicHeavy" !important;
    font-size: 49px !important;
    line-height: 3rem !important;
    //padding-bottom: 10px !important;
    //font-weight: 900 !important;
    margin-top: 0%;
  }
  
  .racePartyName2 {
    font-family: "FranklinGothicHeavy" !important;
    font-size: 49px !important;
    line-height: 3.5rem !important;
    //padding-bottom: 10px !important;
    //font-weight: 900 !important;
    margin-top: 0%;
  }

  .racePartyNameLong {
    font-family: "FranklinGothicHeavy" !important;
    font-size: 40px !important;
    line-height: 3rem !important;
    // padding-bottom: 10px !important;
    margin-top: 0%;
  }
  
  .racePartyNameW {
    font-family: "FranklinGothicHeavy" !important;
    font-size: 82px !important;
    line-height: 82px !important;
    //padding-bottom: 10px !important;
    //font-weight: 900 !important;
    margin-top: 0%;
  }

  .raceboardImgCong {
    width: 7rem !important;
    height: 7rem !important;
    margin-top: 17px;
    margin-bottom: 25px;
  }

  .raceboardImgGov2024 {
    width: 4rem !important;
    height: 4rem !important;
  }

  .raceCheckCong {
    margin-left: -0.1rem !important;
    width: 38px !important;
    height: 38px !important;
  }

  .raceElectCheckCong {
    margin-left: 0.7rem !important;
    width: 43px !important;
    height: 43px !important;
  }

  .raceboardWinnerCong {
    font-size: 2.1rem !important;
  }

  .raceboardWinnerImgBox {
    width: 25% !important;
    //max-height: 10.72rem;
  }

  .raceName {
    font-family: "FranklinGothicDemiCompressed" !important;
    // height: 6rem !important;
    font-size: 3.7rem;
    line-height: 3.5rem;
  }

  .raceTitle {
    font-family: "FranklinGothicBook" !important;
    font-size: 25px !important;
    margin-bottom: 13px !important;
    margin-top: 1rem;
  }
  
  .raceTitleGov {
    font-family: "FranklinGothicBook" !important;
    font-size: 25px !important;
    margin-bottom: 13px !important;
    margin-top: 0rem;
  }
  
  .raceTitleWomen {
    color: inherit;
    font-size: 6.5rem;
    margin-bottom: 19px !important;
    margin-top: -8px;
  }
  
  .raceInterina {
    font-family: "FranklinGothicDemi";
    font-size: 2.5rem;
    color: #555;
    position: relative;
    top: 19px;
  }
  
  .raceNameWomen {
    font-family: "FranklinGothicDemiCompressed" !important;
    height: 6.5rem !important;
    font-size: 4.7rem !important;
    line-height: 4.7rem;
  }
  
  .raceNameWomenSpan {
    bottom: 12px !important;
  }

  .raceCandBox {
    padding-left: 5px !important;
    padding-top: 25px !important;
    margin-top: -14px !important;
  }
  
  .percentNum {
    font-family: "FranklinGothicMedium" !important;
    font-weight: normal !important;
    font-size: 11rem !important;
    margin-top: -0.3rem !important;
  }
  
  .percentNum2 {
    font-family: "FranklinGothicMedium" !important;
    font-weight: normal !important;
    font-size: 9rem !important;
    margin-top: -0.9rem !important;
  }

  .regNum {
    font-family: "FranklinGothicMedium" !important;
    font-size: 4.6rem !important;
    margin-top: 0%;
    line-height: 4rem !important;
    padding-bottom: 2rem;
  }

  .percentNumTurnout {
    font-family: "FranklinGothicMedium" !important;
    font-weight: normal !important;
    font-size: 11rem !important;
    line-height: 6rem !important;
  }

  .percentNumGN {
    font-family: "FranklinGothicMedium" !important;
    font-weight: normal !important;
    font-size: 9.5rem !important;
    margin-top: -1.3rem !important;
  }

  .percentNumCong {
    font-family: "FranklinGothicMedium" !important;
    font-size: 11rem !important;
    font-weight: normal !important;
    margin-bottom: -2.9rem;
    margin-top: -3.1rem;
  }
  
  .percentNumGov {
    font-family: "FranklinGothicMedium" !important;
    font-weight: normal !important;
    font-size: 11rem !important;
    margin-top: -1.1rem !important;
  }

  .percent {
    font-size: 79px !important;
  }
  
  .percent2 {
    font-size: 38px !important;
  }
  
  .percentBig2 {
    font-size: 92px !important;
  }

  .percent3 {
    font-size: 38px !important;
  }

  .voteNum {
    font-family: "FranklinGothicMedium";
    font-size: 2.3rem !important;
    margin-top: 0%;
  }
  
  .voteNum2 {
    font-family: "FranklinGothicMedium";
    font-size: 9rem !important;
    margin-top: 8%;
    line-height: 3.8rem;
  }

  .voteNumBig2 {
    font-family: "FranklinGothicMedium";
    font-size: 12rem !important;
    margin-top: 8%;
    line-height: 3.8rem;
  }

  .voteNumTurnout {
    font-family: "FranklinGothicMedium";
    font-size: 3.2rem !important;
  }

  .voteNumCong {
    font-family: "FranklinGothicMedium";
    font-size: 2.2rem !important;
  }

  .raceChart {
    width: calc(100% - 7.5% - 0px - 0%) !important;
    bottom: 3.7rem !important;
  }

  .raceChartTitle {
    font-size: 3rem !important;
  }

  .raceChartParty {
    font-size: 1.6rem !important;
    margin-bottom: 20px;
  }

  .raceChartNum {
    font-size: 2.6rem !important;
  }

  .raceChartBar {
    margin-bottom: 1.5rem !important;
  }

  .mapboxgl-popup-content {
    padding: 0;
    margin: 0;
    width: 336px;
    background-color: #D5D1FA;
    border: solid 0px #D5D1FA;
    padding-bottom: 0.3rem;
    border-bottom-left-radius: 3px 3px;
    border-bottom-right-radius: 3px 3px;
  }

  .topBar {
    height: 200px;
  }
  
  .govUpdate {
    font-size: 1.7rem;
    margin-top: 1rem;
  }

  .map-root {
    height: calc(100vh - 200px);
    top: 200px !important;
  }

  .yearText {
    font-size: 127px !important;
  }

  .topTitleText {
    font-size: 126px !important;
    padding-top: 1.9rem;
  }

  .ag-cell-value {
    font-size: 28px;
    padding-left: 3px;
    margin-top: 20px;
  }

  .ag-icon {
    font-size: 30px !important;
    margin-top: 23px;
  }

  .ag-row {
    height: 85px !important;
  }

  .mantine-Navbar-root .mantine-ActionIcon-root {
    border-left: none !important;
    padding-left: 0px !important;
    margin-top: -40px !important;
    width: 2.5rem;
    min-height: 2.5rem;
  }

  .mantine-Navbar-root .MuiSvgIcon-root {
    font-size: 2.5rem !important;
  }

  .bookmark_action {
    margin-left: -4px !important;
  }

  .loginBox {
    max-width: 700px;
  }

  #login .title {
    font-size: 127px !important;
  }

  #login .mantine-Button-root {
    margin-top: 74px !important;
  }

  .mantine-Paper-root .mantine-Text-root,
  #login .mantine-Text-root {
    font-size: 33px;
  }

  .mantine-Paper-root .mantine-Button-root,
  #login .mantine-Button-root {
    font-size: 28px;
    margin: 18px;
    height: 60px;
  }

  .mantine-Paper-root .mantine-Title-root,
  #login .mantine-Title-root {
    font-size: 45px;
  }

  .mantine-Paper-root .mantine-TextInput-label,
  #login .mantine-TextInput-label,
  #login .mantine-TextInput-error {
    font-size: 28px;
  }

  .mantine-Paper-root .mantine-TextInput-input,
  #login .mantine-TextInput-input {
    font-size: 28px;
    height: 77px;
    border-bottom: solid 2px $primary_hex;
  }
  
  .Edit .mantine-TextInput-input, .subTitleEdit .mantine-TextInput-input, .Edit .mantine-Select-input {
    height: 50px !important;
    min-height: 50px !important;
  }
  
  .mantine-Select-input, .mantine-Select-dropdown div {
    font-size: 28px;
  }
  
  .mantine-Checkbox-label {
    font-size: 1.7rem;
  }

  .mantine-Notification-root {
    width: 650px;
    right: 200px;
  }

  .mantine-Notification-description {
    font-size: 30px;
  }

  .chrome-picker {
    width: 288px !important;
  }

  .chrome-picker input {
    font-size: 28px !important;
    padding: 10px 0px 7px 0px;
  }

  .chrome-picker label {
    font-size: 16px !important;
    margin-top: 3px !important;
  }

  .colorPickerPop
    .flexbox-fix:nth-of-type(1)
    div:nth-of-type(1)
    div:nth-of-type(1) {
    height: 20px !important;
    margin-top: 0px !important;
    padding-left: 5px;
  }

  .colorPickerColor {
    width: 40px !important;
    height: 40px !important;
  }

  .colorPickerPop {
    left: -15px !important;
    top: -315px !important;
    width: 288px !important;
  }

  .hue-horizontal {
    height: 20px !important;
    margin-top: -5px;
  }

  .hue-horizontal div div {
    width: 12px !important;
    height: 20px !important;
  }
}
